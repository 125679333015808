exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-case-studies-dynamo-js": () => import("./../../../src/pages/case_studies/dynamo.js" /* webpackChunkName: "component---src-pages-case-studies-dynamo-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-features-bestandsverwaltung-js": () => import("./../../../src/pages/features/bestandsverwaltung.js" /* webpackChunkName: "component---src-pages-features-bestandsverwaltung-js" */),
  "component---src-pages-features-buchungen-js": () => import("./../../../src/pages/features/buchungen.js" /* webpackChunkName: "component---src-pages-features-buchungen-js" */),
  "component---src-pages-features-kommunikation-js": () => import("./../../../src/pages/features/kommunikation.js" /* webpackChunkName: "component---src-pages-features-kommunikation-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

